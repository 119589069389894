// Define your theme colors and any other reusable properties as variables

// Colors
// Bootstrap default colors
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$gray-600: #6c757d !default;

$primary-color: #347925; // Green
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$dark-color: #1a1c20; // Dark background color for .App-header
$light-color: #fff; // Commonly used light color
$link-color: #61dafb; // App link color
$black-color: black;
$white-color: #ffffff;
$light-gray-color: rgba(255, 255, 255, 0.7);
$footer-form-border-color: rgba(255, 255, 255, 0.5);
$flash-message-color: #2580d4;
$flash-message-bg: #dff0d8;
$flash-message-border: #d6e9c6;
$header-bg: #1e8c43;

// Typography
$font-family-base: "Roboto", sans-serif;

$navbar-font-size: 15px;
$btn-font-weight: normal;

// Sizes
$btn-square-size: 38px;
$btn-sm-square-size: 32px;
$btn-lg-square-size: 48px;

// Z-index
$back-to-top-z-index: 99;
$spinner-z-index: 99999;
$navbar-z-index: 9999;

// Transition
$transition-duration: 0.5s;

// Misc
$transition-duration: 0.3s;

// Import Bootstrap with overridden variables
$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
);
