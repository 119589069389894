@import "variables";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $dark-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $white-color;
}

.App-link {
  color: $link-color;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $light-color !important;
}

.footer .form-control {
  height: 60px;
  width: 150px;
  border-color: $footer-form-border-color;
}

.asdg-title,
.display-5.animated.fadeIn.mb-4 > .text-main-title,
.title-links {
  color: $primary-color !important;
}

.toggled {
  overflow: visible;
  width: 6.5rem !important;
}

.toggled1 {
  width: 0 !important;
  overflow: hidden;
}

.bg-green,
.card-header:first-child,
.navbar-nav.bg-primary.sidebar.sidebar-light.accordion {
  background-color: $primary-color !important;
  color: $white-color !important;
}

.flash-message {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid $flash-message-border;
  border-radius: 4px;
  color: $flash-message-color;
  background-color: $flash-message-bg;
}

.table-responsive {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow $transition-duration ease-in-out;
}

.table-responsive:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: $back-to-top-z-index;
}

#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration ease-out, visibility 0s linear 0.5s;
  z-index: $spinner-z-index;
}

#spinner.show {
  transition: opacity $transition-duration ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

.bg-dark {
  background-color: $black-color !important;
  color: $white-color !important;
}

.nav-bar {
  position: relative;
  padding: 0 3rem;
  transition: $transition-duration;
  z-index: $navbar-z-index;
}

.nav-bar.sticky-top {
  position: sticky;
  padding: 0;
  z-index: $navbar-z-index;
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 5px;
  transition: $transition-duration;
}

.navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}
.navbar-light .navbar-nav .nav-link {
  color: $primary-color; /* Change to a more distinct color */
  font-weight: bold; /* Make text bolder */
  padding: 1rem; /* Increase padding for better tap targets */
  transition: color 0.3s, background-color 0.3s; /* Smooth transitions for hover effects */
  position: relative; /* Needed for absolute positioning of the pseudo-elements */
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: $dark-color; /* Change text color on hover/focus */
  text-decoration: none; /* Removes underline */
}

.navbar-light .navbar-nav .nav-link.active {
  color: $dark-color;
}
.navbar-light .navbar-nav .nav-link.active::after {
  content: ""; /* Required for pseudo-elements */
  position: absolute;
  left: 50%;
  bottom: 5px; /* Adjust so it does not push the text up */
  transform: translateX(-50%);
  width: 6px; /* Width of the dot */
  height: 6px; /* Height of the dot */
  background-color: $dark-color;
  border-radius: 50%;
  transition: background-color 0.3s;
}

/* Smaller Computers */
@media (min-width: 1024px) and (max-width: 1279px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
  }
}

/* Big Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  .nav-bar {
    margin: 0;
    padding: 0;
  }
  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
    margin-right: 0;
    padding: 0.75rem 1rem; /* Slightly smaller padding on smaller screens */
  }

  .navbar-light .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

@media (min-width: 1023px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    top: 100%;
    margin-top: 0;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
    opacity: 0;
    visibility: hidden;
    transition: $transition-duration;
  }
  .navbar .nav-item:hover .dropdown-menu {
    transform: rotateX(0deg);
    visibility: visible;
    transition: $transition-duration;
    opacity: 1;
  }
}

.cat-item div {
  background: $white-color;
  border: 1px dashed rgba(0, 185, 142, 0.3); // Consider variable for rgba color
  transition: $transition-duration;
}

.cat-item:hover div {
  background: $primary-color;
  border-color: transparent;
}

.cat-item div * {
  transition: $transition-duration;
}

.cat-item:hover div * {
  color: $white-color !important;
}

/*** Footer ***/
.footer {
  .btn {
    &.btn-social {
      margin-right: 5px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $light-color;
      border: 1px solid rgba(255, 255, 255, 0.5); // Consider moving rgba value to a variable if reused
      border-radius: 35px;
      transition: $transition-duration;

      &:hover {
        color: $primary-color;
        border-color: $light-color;
      }
    }

    &.btn-link {
      display: block;
      margin-bottom: 5px;
      padding: 0;
      text-align: left;
      font-size: 15px;
      font-weight: normal;
      text-transform: capitalize;
      transition: $transition-duration;

      &::before {
        position: relative;
        content: "\f105";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-right: 10px;
      }

      &:hover {
        letter-spacing: 1px;
        box-shadow: none;
      }
    }
  }

  .form-control {
    border-color: rgba(
      255,
      255,
      255,
      0.5
    ); // Consider moving rgba value to a variable if reused
  }

  .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, 0.1); // Note: rgba value seems incorrect (256), consider correcting or using a variable
  }

  .copyright a {
    color: $light-color;
  }

  .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.1); // Consider moving rgba value to a variable if reused

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: none;
    }
  }
}

// New styles
a {
  color: $primary-color;
  text-decoration: none;
}

.btn-link {
  text-decoration: none;
}

.accordion-button {
  background-color: $primary-color !important;
  color: $white-color !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

// /* BaseLayout.css */
.layout-sidebar,
.layout-content {
  display: flex;
  flex-direction: column;
}

/* Make the sidebar stretch to match the content's height */
.layout-sidebar {
  height: 100%;
}

/* Optional: Ensure the sidebar sticks to the top under all circumstances */
.layout-sidebar {
  position: sticky;
  top: 0;
}

/* Remove padding if desired for full-width content */
.layout-content {
  padding: 0;
}

/* Ensures the footer is pushed to the bottom */
.footer {
  margin-top: auto;
}

.sidebar {
  min-height: 100vh;
}

.sidebar .nav-item .nav-link {
  display: block;
  width: 100%;
  text-align: left;
  padding: 1rem;
  width: 14rem;
  color: $light-gray-color;
  font-size: 15px;
  text-transform: none;
  outline: none;
  font-weight: 500;
  span {
    font-size: 0.85rem;
    display: inline;
  }
  &.active,
  :active {
    color: $white-color;
  }
  &.header {
    font-weight: bold;
    color: $light-gray-color;
  }
}
.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  max-width: 100%; /* Optional: To prevent the table from exceeding its parent's width */
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
  float: left;
}

.card {
  border: none;
  box-shadow: 0 4px 8px rgba(34, 11, 11, 0.15);
  transition: box-shadow 0.2s ease-in-out;
  font-family: "Roboto", sans-serif;
}

.card:hover {
  box-shadow: 0 8px 36px rgba(0, 0, 0, 0.2);
}

.card-body {
  padding: 1.5rem;
}

.col-sm-12 {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.bg-green,
.card-header:first-child,
.bg-primary.sidebar.sidebar-light.accordion {
  background: linear-gradient(to bottom, #347925, #28661e);
  color: #ffffff;
  border: none;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.card-header:first-child:hover,
.bg-primary.sidebar.sidebar-light.accordion a:hover {
  background: linear-gradient(to bottom, #28661e, #1c5317);
}

#table-of-contents ul li a {
  font-size: 14px;
}

.ome-video-bg {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust height as needed */
  overflow: hidden;
  object-fit: cover;
}

.video-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.txt-intro {
  font-size: 2.5rem; /* Adjust font size as needed */
  font-weight: bold;
  color: white; /* Text color */
  margin: 0; /* Remove default margin */
  padding: 20px; /* Add padding as needed */
  margin-left: 250px;
}

.news-flash {
  position: absolute;
  top: 50%;
  color: #ffffff;
  font-size: 39px;
  left: 0;
  width: 100%;
  overflow: hidden;
  position: sticky;
}

.news-flash-text {
  display: inline-block;
  white-space: nowrap;
  margin: auto;
}

@keyframes newsFlash {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.asdg-hometitle {
  color: #ffffff;
  font-weight: 900;
  font-size: larger;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 767.98px) {
  .txt-intro {
    display: none;
  }
}

@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

  /*
	Label the data
	*/

  // td:nth-of-type(1):before { content: "ID"; }
  // td:nth-of-type(2):before { content: "Category"; }
  // td:nth-of-type(3):before { content: "Counties"; }
  // td:nth-of-type(4):before { content: "Date"; }
  // td:nth-of-type(5):before { content: "Topics"; }
  // td:nth-of-type(6):before { content: "Status"; }
  // td:nth-of-type(6):before { content: "Data Access"; }

  /* Add padding between content and table cell */
  td:before {
    padding: 30px; /* Adjust the padding value as needed */
  }
}

// logo
.ministry-logo {
  display: flex;
  align-items: center;
  text-align: center;
}

.logo-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flag-divider {
  width: 2px;
  height: 50px;
  background: linear-gradient(
    to bottom,
    #000000 32%,
    #ffffff 32% 34%,
    #be3a34 34% 66%,
    #ffffff 66% 68%,
    #009a44 68%
  ); /* Black, White, Red, White, Green */
  margin: 0 8px; /* Small space on either side */
}

.logo-coat-of-arms {
  height: 60px;
  width: auto;
  margin-bottom: 2px;
}

.caption {
  font-size: 8px;
  text-transform: uppercase;
  color: #000;
}

.logo-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-weight: bold;
}

.text-top,
.text-bottom {
  font-size: 24px;
  line-height: 28px;
  color: #000;
  font-family: "Arial", sans-serif;
}

/* Large Desktops */
@media (min-width: 1280px) {
  .text-top,
  .text-bottom {
    font-size: 24px;
    line-height: 28px;
  }
  .logo-coat-of-arms {
    height: 60px;
    width: auto;
  }
}

/* Desktops */
@media (min-width: 1024px) and (max-width: 1279px) {
  .text-top,
  .text-bottom {
    font-size: 18px;
    line-height: 20px;
  }
  .logo-coat-of-arms {
    height: 40px;
    width: auto;
  }
  .flag-divider {
    height: 40px;
  }
}

/* Big Tablets */
@media (min-width: 991px) and (max-width: 1023px) {
  .text-top,
  .text-bottom {
    font-size: 18px;
    line-height: 20px;
  }
  .logo-coat-of-arms {
    height: 40px;
    width: auto;
  }
  .flag-divider {
    height: 40px;
    margin: 0 5px; /* Adjusted margin */
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 991px) {
  .text-top,
  .text-bottom {
    font-size: 18px;
    line-height: 20px;
  }
  .logo-coat-of-arms {
    height: 40px;
    width: auto;
  }
  .flag-divider {
    height: 40px;
    margin: 0 5px; /* Adjusted margin */
  }
}

/* Mobile Phones */
@media (max-width: 767px) {
  .logo-coat-of-arms {
    height: 30px;
    width: auto;
  }

  .flag-divider {
    height: 33px;
    margin: 0 5px;
  }

  .text-top,
  .text-bottom {
    font-size: 15px;
    line-height: 17px;
  }
  .caption {
    font-size: 5px;
    text-transform: uppercase;
    color: #000;
  }
}

.header-sm-lg {
  font-size: 14px;
}

.text-sm-lg {
  font-size: 12px;
}

@media (min-width: 992px) {
  /* Corresponds to Bootstrap's 'lg' breakpoint */
  .header-sm-lg,
  .text-sm-lg {
    font-size: initial; /* Resets to the default font size on 'lg' and larger */
  }
}
